import {
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TableCell,
  TableFooter,
  TableRow,
  Theme,
  Typography,
  Zoom,
} from "@material-ui/core";
import {
  MUIDataTableColumnDef,
  MUIDataTableColumnState,
  MUIDataTableOptions,
} from "mui-datatables";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import "regenerator-runtime/runtime";

import { LDPUIDataTable } from "../../components";
import { RootState } from "../../state";
import { salesScreenSearchStateProps } from "../../state/salesScreenReducer";
import { convertDate, getTimezoneSetting, ISODateFormat } from "../../common/utils/date";
import SalesScreenLeadCountGraph from "./SalesScreenLeadCountGraph";
import { tabExist, TabProps } from "../../components/tabs";
import { tabsSectionStateProps, updateTabContainers } from "../../state/tabsReducer";
import { TAB_CONTAINER } from "../../pages/salesscreen";
import SalesBuyerCapacityDetails from "./salesBuyerCapDetails";
import { DateTime } from "luxon";
import { ViewList } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import LoadingTableSkeleton from "../../components/loadingTableSkeleton";
import CustomMuiDatatableFilter, { buyerFilter, distributionFilter, leadsSoldFilter, revenueFilter, rptFilter } from "../../components/customMuiDatatableFilter";

interface Props {
  salesScreenSearch?: salesScreenSearchStateProps;
  tabsSection?: tabsSectionStateProps;
  dispatch?: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    loadingGrid: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    loadingPaper: {
      height: 40,
    },
    salesScreenFooterCell: {
      padding: "0 10px",
      fontWeight: "bold",
      color: "#000",
      fontSize: "1em",
      "& .preLabel": {
        color: "#787878",
      },
      textAlign: "right",
    },
    expandableRowCell: {
      textAlign: "right",
      padding: "0 10px",
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    },
    rowCell: {
      textAlign: "right",
      padding: "0 10px",
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    },
    hidden: {
      display: "none"
    }
  })
);

const SalesScreenLeadCountByDaily = ({ salesScreenSearch, tabsSection, dispatch }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [salesScreenData, setSalesScreenData] = useState<any[]>([]);

  const [salesScreenDailyData, setSalesScreenDailyData] = useState<any[]>([]);
  const [salesScreenGraphData, setSalesScreenGraphData] = useState<any[]>([]);

  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    'PingTotal', 'PingsAccepted', 'PingAcceptedRate', 'PostTotal', 'PostsAccepted', 'PostAcceptedRate'
  ]);

  useEffect(() => {
    const dataState = salesScreenSearch?.lastLoadedData?.Daily?.state || "";

    // console.log(salesScreenSearch);

    setLoading(dataState === "loading");

    if (dataState === "ready") {
      const screenData: any[] = JSON.parse(
        salesScreenSearch?.lastLoadedData?.Daily?.data?.LDPIngestQueryGroup
          ?.GetOpsScreenInsuranceSaleByBuyer ?? "[]"
      );
      
      setSalesScreenDailyData(screenData);

      let newData: any[] = [], BuyerId: number = 0;
      screenData.map((data) => {
        if (!newData[data.BuyerName]) {
          newData[data.BuyerName] = { ...data };

          if (BuyerId < 1) BuyerId = data?.BuyerId ?? 0;
        } else {
          newData[data.BuyerName] = {
            "BuyerId": data.BuyerId,
            "BuyerName": data.BuyerName,
            "LeadDistribution": newData[data.BuyerName].LeadDistribution += data.LeadDistribution,
            "LeadsSold": newData[data.BuyerName].LeadsSold += data.LeadsSold,
            "RPT": newData[data.BuyerName].RPT += data.RPT,
            "TotalRevenue": newData[data.BuyerName].TotalRevenue += data.TotalRevenue,
            "PingTotal": newData[data.BuyerName].PingTotal += data.PingTotal,
            "PingsAccepted": newData[data.BuyerName].PingsAccepted += data.PingsAccepted,
            "PingAcceptedRate": newData[data.BuyerName].PingAcceptedRate += data.PingAcceptedRate,
            "PostTotal": newData[data.BuyerName].PostTotal += data.PostTotal,
            "PostsAccepted": newData[data.BuyerName].PostsAccepted += data.PostsAccepted,
            "PostAcceptedRate": newData[data.BuyerName].PostAcceptedRate += data.PostAcceptedRate,
          }
        }
        // "PingTotal":24,"PingsAccepted":24,"PingAcceptedRate":100.00,"PostTotal":13,"PostsAccepted":13,"PostAcceptedRate":100.00}
      });

      // recalculate RPT according to formula total_revenue / leads_sold
      setSalesScreenData(Object.values(newData).map((data) => ({ 
        ...data, 
        RPT: data.TotalRevenue / data.LeadsSold, 
        PingAcceptedRate: data.PingAcceptedRate / screenData.filter((screen) => screen.BuyerName == data.BuyerName).length,
        PostAcceptedRate: data.PostAcceptedRate / screenData.filter((screen) => screen.BuyerName == data.BuyerName).length,
      })));
    }
  }, [salesScreenSearch]);

  // method for spawning tabs for buyer cap details
  const createBuyerCapDetailsTab = (buyerName: string) => {
    const buyerTabId = buyerName.trim().toLowerCase().replaceAll(/\s/g, "_");
    const tabId = `sales-screen-buyer-cap-details-${buyerTabId}`;

    const tabExists = tabExist(
      tabsSection?.tabContainers[TAB_CONTAINER].tabs,
      tabId
    );

    if (tabExists > 0) {
      const updatedTabs = {
        [TAB_CONTAINER]: {
          tabs: tabsSection?.tabContainers[TAB_CONTAINER].tabs,
          tabFocus: tabExists,
        },
      };

      dispatch && dispatch(updateTabContainers(updatedTabs));
    } else {
      const content = <SalesBuyerCapacityDetails 
        buyerName={buyerName} />;

      const buyerCapDetailsTab = {
        title: `${buyerName} Capacity Details`,
        tabId,
        content,
        closeTab: handleCloseTab,
      };

      const updatedTabs = {
        [TAB_CONTAINER]: {
          tabs: [
            ...tabsSection?.tabContainers[TAB_CONTAINER].tabs,
            buyerCapDetailsTab,
          ],
          tabFocus: tabsSection?.tabContainers[TAB_CONTAINER].tabs.length,
        },
      };

      dispatch && dispatch(updateTabContainers(updatedTabs));
    }
  }

  const handleCloseTab = (tabs: TabProps[]) => {
    dispatch && dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: tabs.length - 1,
          tabs: tabs,
        },
      })
    );
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "BuyerName",
      label: "Buyer Name",
      options: {
        viewColumns: true,
        filter: false,
        setCellProps: value => ({
          style: {
            fontWeight: "bold"
          }
        }),
      },
    },
    {
      name: "LeadsSold",
      label: "Sold Leads",
      options: {
        viewColumns: true,
        filter: true,
        setCellHeaderProps: value => {
          return {
            style: {
              textAlign: 'center',
            },
          };
        },
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        ...leadsSoldFilter
      },
    },
    {
      name: "LeadDistribution",
      label: "Lead Distribution",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <>
            <NumberFormat
              value={(value * 100).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />%
          </>
        ),
        ...distributionFilter
      },
    },
    {
      name: "TotalRevenue",
      label: "Revenue",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <>
            $<NumberFormat
              value={value}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
            />
          </>
        ),
        ...revenueFilter
      },
    },
    {
      name: "RPT",
      label: "RPL ",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
          <>
            $<NumberFormat
              value={value.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
            />
          </>
        )},
        ...rptFilter,
      },
    },
    {
      name: 'CapacityDetails',
      // name: "BuyerName",
      label: "Cap Details",
      options: {
        viewColumns: true,
        filter: false,
        sort: false,
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          // console.log(value, "table", );
          return (
            <>
              <IconButton 
                aria-label='Show Capacity Status'
                onClick={(e) => {
                  createBuyerCapDetailsTab(tableMeta.rowData[0]);
                }}
              >
                <ViewList />
              </IconButton>
            </>
          )
        },
      },
    },
    {
      name: "PingTotal",
      label: "Ping Total",
      options: {
        viewColumns: true,
        display: false,
        filter: true,
        setCellHeaderProps: value => {
          return {
            style: {
              textAlign: 'center',
            },
          };
        },
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        ...leadsSoldFilter
      },
    },
    {
      name: "PingsAccepted",
      label: "Pings Accepted",
      options: {
        viewColumns: true,
        display: false,
        filter: true,
        setCellHeaderProps: value => {
          return {
            style: {
              textAlign: 'center',
            },
          };
        },
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        ...leadsSoldFilter
      },
    },
    {
      name: "PingAcceptedRate",
      label: "Ping Accepted Rate",
      options: {
        viewColumns: true,
        display: false,
        filter: true,
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <>
            <NumberFormat
              value={(value).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />%
          </>
        ),
        ...distributionFilter
      },
    },
    {
      name: "PostTotal",
      label: "Post Total",
      options: {
        viewColumns: true,
        display: false,
        filter: true,
        setCellHeaderProps: value => {
          return {
            style: {
              textAlign: 'center',
            },
          };
        },
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        ...leadsSoldFilter
      },
    },
    {
      name: "PostsAccepted",
      label: "Posts Accepted",
      options: {
        viewColumns: true,
        display: false,
        filter: true,
        setCellHeaderProps: value => {
          return {
            style: {
              textAlign: 'center',
            },
          };
        },
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        ...leadsSoldFilter
      },
    },
    {
      name: "PostAcceptedRate",
      label: "Post Accepted Rate",
      options: {
        viewColumns: true,
        display: false,
        filter: true,
        setCellProps: value => ({
          style: {
            textAlign: "right",
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
          }
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <>
            <NumberFormat
              value={(value).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />%
          </>
        ),
        ...distributionFilter
      },
    },
  ];

  const getFooterCell = (showEffects:boolean, cols:any[], columnName: string, index: number) => {
    if (columnName === "BuyerName"){
      if(cols[index].label !== "Cap Details"){
        return <TableCell key={index} style={showEffects ? { backgroundColor: "#ede4e4", color: "#ff00ff" } : {}}>
          { showEffects ? "Notice: Your totals are impacted by the current filters you have selected." : " " }
        </TableCell>;
      }

      return null;
    }
      

    const visibleRows = salesScreenData.reduce((p, c, i) => {
      return p + (isRowFiltered(cols, i) ? 0 : 1);
    }, 0);

    let total = salesScreenData.reduce((total, it, i) => (total + (isRowFiltered(cols, i) ? 0 : it[columnName])), 0);
    let isDollar = false;

    if (['TotalRevenue', 'RPT'].includes(columnName)) {
      if (columnName === "RPT") {
        const totalRPTAverage = salesScreenData.reduce((totalRPTAverage, it, i) =>
          ((totalRPTAverage + (isRowFiltered(cols, i) ? 0 : it['RPT']) ))
          , 0);
        total = totalRPTAverage / visibleRows;
      }
      total = parseFloat(total.toFixed(2));
      isDollar = true;
    }

    if (columnName === "LeadDistribution")
      return <TableCell key={index} className={classes.salesScreenFooterCell} style={showEffects ? { backgroundColor: "#ede4e4" } : {}}>
        {Math.round((total / visibleRows) * 100)}%
      </TableCell>;

    if (['PingAcceptedRate', 'PostAcceptedRate'].includes(columnName))
      return <TableCell key={index} className={classes.salesScreenFooterCell} style={showEffects ? { backgroundColor: "#ede4e4" } : {}}>
        {Math.round((total / visibleRows))}%
      </TableCell>;

    return (
      <TableCell key={index} className={classes.salesScreenFooterCell} style={showEffects ? { backgroundColor: "#ede4e4" } : {}}>
        {isDollar && '$'}<NumberFormat
          value={total}
          displayType={"text"}
          thousandSeparator={true}
        />
      </TableCell>
    );
  }

  const isRowFiltered = (colums: any[], index:number) => {
    let filtered = false;
    colums.forEach(column => {
      if(Boolean(column.filter || true)){
        const columnValue = salesScreenData[index][column.name];

        if(column.filterOptions?.logic){
          filtered ||= column.filterOptions.logic(columnValue, column.filterList || []);
        } else {
          const filterList = column.filterList || [];
          filtered ||= !!filterList.length ? !filterList.map((f) => `${f}`.toLowerCase()).includes(columnValue.toLowerCase()) : false;
          
        }
      }
    });

    return filtered;
  }

  const options: MUIDataTableOptions = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "BuyerName",
      direction: "asc",
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [25, 50, 100],
    fixedHeader: true,
    fixedSelectColumn: true,
    expandableRows: true,
    expandableRowsOnClick: true,
    customTableBodyFooterRender: (opts: any) => {
      let filtersInEffect = false;
      opts.columns.forEach(col => {
        filtersInEffect ||= (col.filterList || []).length > 0;
      });

      return (
        <TableFooter>
          <TableRow>
            {opts.expandableRows !== "none" ? <TableCell /> : null}
            {opts.columns.map((column: MUIDataTableColumnState, index: number) => {
            const cell = column.display === "true" ? getFooterCell(filtersInEffect, opts.columns, column.name, index) : null;
              return filtersInEffect && !!cell ? (
                <Zoom in>
                  {cell}
                </Zoom>
              ) : cell;
            })}
          </TableRow>
        </TableFooter>
      )
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      // if (dataIndex === 3 || dataIndex === 4) return false;

      // // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      // if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
      return true;
    },
    setRowProps: (row, rowIndex) => {
      let data = salesScreenGraphData[0] ?? undefined;
      if (data && data.BuyerName === row[0]) {
        return {
          style: {
            backgroundColor: "#fed766"
          }
        }
      }
      return {};
    },
    onRowClick: (rowData, rowMeta) => {
      if (salesScreenData) {
        const data = salesScreenData[rowMeta?.dataIndex];

        setSalesScreenGraphData(salesScreenDailyData?.filter(it => it?.BuyerId === data?.BuyerId) ?? []);
      }
    },
    onViewColumnsChange: (changedColumn: string, action: string) => {
      const hiddenCols = hiddenColumns;
      if(action === "remove") {
        hiddenCols.push(changedColumn);
      }else{
        const index = hiddenCols.indexOf(changedColumn);
        if (index > -1) { 
          hiddenCols.splice(index, 1);
        }
      }
      setHiddenColumns(hiddenCols);
      
      
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const screenDataRow: any[] = JSON.parse(
        salesScreenSearch?.lastLoadedData?.Daily?.data?.LDPIngestQueryGroup
          ?.GetOpsScreenInsuranceSaleByBuyer ?? "[]"
      );
      let expandableRowData = screenDataRow.filter(data => data.BuyerName === rowData[0]);
      return (
        expandableRowData.map((row, i) => {
          return (
            <TableRow key={`${rowMeta.rowIndex}-${i}`}>
              <TableCell colSpan={2} style={{ textAlign: "center", padding: "0 10px" }}> {convertDate.toShortDate(row.SoldDate)} </TableCell>
              { !hiddenColumns.includes("LeadsSold") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  <NumberFormat
                    value={row.LeadsSold}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </TableCell>
              }
              { !hiddenColumns.includes("LeadDistribution") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  <NumberFormat
                    value={(row.LeadDistribution * 100)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />%
                </TableCell>
              }
              { !hiddenColumns.includes("TotalRevenue") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  $<NumberFormat
                    value={row.TotalRevenue}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                </TableCell>
              }
              { !hiddenColumns.includes("RPT") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  $<NumberFormat
                    value={row.RPT}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                </TableCell>
              }
              <TableCell className={`${classes.expandableRowCell}`}>
                &nbsp;
              </TableCell>
              { !hiddenColumns.includes("PingTotal") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  <NumberFormat
                    value={row.PingTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </TableCell>
              }
              { !hiddenColumns.includes("PingsAccepted") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  <NumberFormat
                    value={row.PingsAccepted}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </TableCell>
              }
              { !hiddenColumns.includes("PingAcceptedRate") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  <NumberFormat
                    value={(row.PingAcceptedRate)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />%
                </TableCell>
              }
              { !hiddenColumns.includes("PostTotal") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  <NumberFormat
                    value={row.PostTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </TableCell>
              }
              { !hiddenColumns.includes("PostsAccepted") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  <NumberFormat
                    value={row.PostsAccepted}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </TableCell>
              }
              { !hiddenColumns.includes("PostAcceptedRate") &&
                <TableCell className={`${classes.expandableRowCell}`}>
                  <NumberFormat
                    value={(row.PostAcceptedRate)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />%
                </TableCell>
              }
            </TableRow>)
        })
      );
    },
  };

  if (loading) {
    return <LoadingTableSkeleton>
      <Typography variant="h6">Buyer and Buyer Contract Metrics</Typography>
    </LoadingTableSkeleton>;
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={6}>
        <LDPUIDataTable
          ldpTableId="sales-screen-lead-count-hourly"
          restoreFilters={true}
          title={<Typography variant="h6">Buyer and Buyer Contract Metrics</Typography>}
          data={salesScreenData}
          columns={columns}
          options={options}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SalesScreenLeadCountGraph data={salesScreenGraphData} />
      </Grid>
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    tabsSection: state.tabsSection,
    salesScreenSearch: state.salesScreenSearch,
  }),
  null
)(SalesScreenLeadCountByDaily);
