import {
  Checkbox,
  createStyles,
  Fade,
  FormControl,
  Grow,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableFooter,
  TableRow,
  Theme,
  Typography,
  Zoom,
} from "@material-ui/core";
import { FilterType, MUIDataTableColumn, MUIDataTableColumnOptions, MUIDataTableColumnState, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import "regenerator-runtime/runtime";
import { GetSalesScreens } from "../../common/models/types/GetSalesScreens";
import { valueIsWithinRange } from "../../common/utils/number";
import { LDPUIDataTable } from "../../components";
import CustomMuiDatatableFilter, { buyerFilter, leadsSoldFilter, rptFilter } from "../../components/customMuiDatatableFilter";
import LoadingTableSkeleton from "../../components/loadingTableSkeleton";

interface Props {
  loading: boolean;
  data?: GetSalesScreens;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    loadingGrid: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    loadingPaper: {
      height: 40,
    },
    salesScreenFooterCell: {
      padding: "0 10px",
      fontWeight: "bold",
      color: "#000",
      fontSize: "1em",
      "& .preLabel": {
        color: "#787878",
      },
      textAlign: "right",
    },
    expandableRowCell: {
      textAlign: "right",
      padding: "0 10px",
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    },
    rowCell: {
      textAlign: "right",
      padding: "0 10px",
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  })
);

const SalesScreenLeadCountToday = ({ loading, data }: Props) => {
  const classes = useStyles();

  const [salesScreenData, setSalesScreenData] = useState<any[]>([]);

  useEffect(() => {
    if (!loading) {

      const screenData: any[] = JSON.parse(
        data?.LDPIngestQueryGroup?.GetOpsScreenInsuranceSaleByBuyer ?? "[]"
      );

      let newData: any[] = [], BuyerId: number = 0;
      screenData.map((data) => {
        if (data.ContractId !== null) {
          if (!newData[data.BuyerName]) {
            newData[data.BuyerName] = data;
            newData[data.BuyerName].RPTToday = Number(newData[data.BuyerName].RPTToday || 0); // sometimes there is no RPT value

            if (BuyerId < 1) BuyerId = data?.BuyerId ?? 0;
          } else {
            newData[data.BuyerName] = {
              "Count": (newData[data.BuyerName].Count || 1) + 1,
              "BuyerId": data.BuyerId,
              "BuyerName": data.BuyerName,
              "SoldLeadsToday": (newData[data.BuyerName].SoldLeadsToday || 0) + (data.SoldLeadsToday || 0),
              "RPTToday": (newData[data.BuyerName].RPTToday || 0) + (Number(data.RPTToday) || 0),
              "SoldLeadsComparison": (newData[data.BuyerName].SoldLeadsComparison || 0) + (data.SoldLeadsComparison || 0),
              "RPTComparison": (newData[data.BuyerName].RPTComparison || 0) + (data.RPTComparison || 0),
            }
          }
        }
      });

      // calculate RPTToday average
      // in fill missing attrobutes with zero
      for(const buyer in newData){
        // console.log(buyer, newData[buyer].RPTToday, newData[buyer].Count, ((newData[buyer].RPTToday || 0) / (newData[buyer].Count || 1)));
        newData[buyer].RPTToday = ((newData[buyer].RPTToday || 0) / (newData[buyer].Count || 1));
        newData[buyer].TotalRevenueToday = newData[buyer].TotalRevenueToday || 0;
        newData[buyer].TotalRevenueComparison = newData[buyer].TotalRevenueComparison || 0;
        newData[buyer].SoldLeadsToday = newData[buyer].SoldLeadsToday || 0;
        newData[buyer].SoldLeadsComparison = newData[buyer].SoldLeadsComparison || 0;
      }

      // console.log('newData', newData);
      setSalesScreenData(
        Object.values(newData)
      );
    }
  }, [data, loading]);

  const cellPropsStyle = {
    style: {
      textAlign: "right",
      fontWeight: "bold",
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: "BuyerName",
      label: "Buyer Name",
      options: {
        viewColumns: true,
        filter: true,
        ...buyerFilter,
      },
    },
    {
      name: "SoldLeadsToday",
      label: "Sold Leads Today",
      options: {
        viewColumns: true,
        setCellProps: value => (cellPropsStyle),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        filter: true,
        ...leadsSoldFilter
      },
    },
    {
      name: "RPTToday",
      label: "RPL Today",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: value => (cellPropsStyle),
        customBodyRender: (value: any, tableMeta: any) => (
          <>
            $
            <NumberFormat
              value={value.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          </>
        ),
        ...rptFilter
      },
    },
    {
      name: "SoldLeadsComparison",
      label: "Leads Sold Same Day Last Week",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: value => (cellPropsStyle),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        ...leadsSoldFilter,
      },
    },
    {
      name: "RPTComparison",
      label: "RPL Same Day Last Week",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: value => (cellPropsStyle),
        customBodyRender: (value: any, tableMeta: any) => (
          <>
            $
            <NumberFormat
              value={value.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          </>
        ),
        ...rptFilter,
      },
    },
  ];

  const getFooterCell = (showEffects: boolean, columns: any[], columnName: string, index: number) => {
    if (columnName === "BuyerName")
      return <TableCell colSpan={2} key={index} style={showEffects ? { backgroundColor: "#ede4e4", color: "#ff00ff" } : {}}>
        {showEffects ? "Notice: Your totals are impacted by the current filters you have selected." : " "}
      </TableCell>;

    let total = salesScreenData.reduce(
      (total, it, i) => isRowFiltered(columns, i) ? total : total + it[columnName],
      0
    );
    
    let isDollar = false;
    if (["RPTToday", "RPTComparison"].includes(columnName)) {
      let ColRevenueName = columnName === 'RPTToday' ? 'TotalRevenueToday' : 'TotalRevenueComparison';
      let ColSoldLeads = columnName === 'RPTToday' ? 'SoldLeadsToday' : 'SoldLeadsComparison';

      let totalRevenue = salesScreenData.reduce(
        (total, it, i) => isRowFiltered(columns, i) ? total : total + Number(it[ColRevenueName]),
        0
      );

      let totalSoldLeads = salesScreenData.reduce(
        (total, it, i) => isRowFiltered(columns, i) ? total : total + it[ColSoldLeads],
        0
      );

      // console.log(columnName, totalRevenue, totalSoldLeads);

      total = totalRevenue / totalSoldLeads;
      total = parseFloat(total.toFixed(2));
      isDollar = true;      
    }

    return (
      <TableCell key={index} className={classes.salesScreenFooterCell} style={showEffects ? { backgroundColor: "#ede4e4" } : {}}>        
        {isDollar && "$"}
        <NumberFormat
          value={total}
          displayType={"text"}
          thousandSeparator={true}
        />
      </TableCell>
    );
  };

  const isRowFiltered = (colums: any[], index: number) => {
    let filtered = false;
    colums.forEach(column => {
      if (Boolean(column.filter || true)) {
        const columnValue = salesScreenData[index][column.name];
        if (column.filterOptions?.logic) {
          filtered ||= column.filterOptions.logic(columnValue, column.filterList || []);
        } else {
          const filterList = column.filterList || [];
          filtered ||= !!filterList.length ? !filterList.map((f) => `${f}`.toLowerCase()).includes(columnValue.toLowerCase()) : false;
        }
      }
    });

    return filtered;
  }

  const options: MUIDataTableOptions = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "BuyerName",
      direction: "asc",
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [25, 50, 100],
    fixedHeader: true,
    fixedSelectColumn: true,
    customTableBodyFooterRender: (opts) => {
      let filtersInEffect = false;
      opts.columns.forEach(col => {
        filtersInEffect ||= (col.filterList || []).length > 0;
      });

      const footer = (
        <TableFooter>
          <TableRow>
            {opts.selectableRows !== "none" ? <TableCell /> : null}
            {opts.columns.map((column: MUIDataTableColumnState, index: number) => {
              const cell = column.display === "true" ? getFooterCell(filtersInEffect, opts.columns, column.name, index) : null;
              return filtersInEffect && !!cell ? (
                <Zoom in>
                  {cell}
                </Zoom>
              ) : cell;
            })}
          </TableRow>
        </TableFooter>
      );

      return footer;
    },

    expandableRows: true,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const screenDataRow: any[] = JSON.parse(
        data?.LDPIngestQueryGroup
          ?.GetOpsScreenInsuranceSaleByBuyer ?? "[]"
      );
      let expandableRowData = screenDataRow.filter(data => (data.BuyerName === rowData[0] && data.ContractId !== null));

      return (
        expandableRowData.map((row, i) => {
          return (
            <TableRow key={`${rowMeta.rowIndex}-${i}`}>
              <TableCell colSpan={2} style={{ textAlign: "center", padding: "0 10px" }}> {row.ContractName} </TableCell>
              <TableCell className={classes.expandableRowCell}>
                <NumberFormat
                  value={row.SoldLeadsToday}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell className={classes.expandableRowCell}>
                $<NumberFormat
                  value={row.RPTToday}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                />
              </TableCell>
              <TableCell className={classes.expandableRowCell}>
                <NumberFormat
                  value={row.SoldLeadsComparison}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell className={classes.expandableRowCell}>
                $<NumberFormat
                  value={row.RPTComparison}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                />
              </TableCell>
            </TableRow>)
        })
      );
    },
  };

  if (loading) {
    return (
      <LoadingTableSkeleton>
        <Typography variant="h6">Lead Count by Buyer Today</Typography>
      </LoadingTableSkeleton>
    );
  }

  return (
    <LDPUIDataTable
      ldpTableId="sales-screen-lead-count-summary"
      restoreFilters={true}
      title={<Typography variant="h6">Lead Count by Buyer Today</Typography>}
      data={salesScreenData}
      columns={columns}
      options={options}
    />
  );
};

export default SalesScreenLeadCountToday;
