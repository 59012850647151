import "regenerator-runtime/runtime";
import React, { useEffect, useState } from "react";

import loadable from '@loadable/component';

const Plot = loadable(() => import('react-plotly.js'));

interface SalesScreenListProps {
  BuyerId: number;
  BuyerName: string;
  LeadsSold: number;
  RPT: number;
  SoldDate: string;
}

interface Props {
  data: Array<SalesScreenListProps>|null;  
}

interface GraphDataProps {
  Dates: string[];
  Solds: number[];
  RPLs: number[];
}

const SalesScreenLeadCountGraph = ({
    data    
}: Props) => {  

  const [graphData, setGraphData] = useState<GraphDataProps>({
    Dates: [],
    Solds: [],
    RPLs: []
  });

  useEffect(() => {    
    let Dates:string[] = [], 
        Solds:number[] = [], 
        RPLs:number[] = [];

    if (data) {
      data?.map((it) => {
        Dates.push(it?.SoldDate);
        Solds.push(it?.LeadsSold);
        RPLs.push(it?.RPT);
      });
    }
        
    setGraphData({
      Dates,
      Solds,
      RPLs
    });
              
  }, [data]);

  return (<Plot
    data={[
      {
        type: 'bar', 
        name: 'Lead Solds',
        marker: {color: '#fed766'},
        x: graphData?.Dates,
        y: graphData?.Solds,             
      },
      {        
        type: 'scatter',
        mode: 'lines+markers+text',
        marker: {color: '#2bb7ca'},
        name: 'RPL',
        x: graphData?.Dates,
        y: graphData?.RPLs, 
        text: graphData?.RPLs?.map((value) => (`$${value}`)),  
        textposition: 'top',
        yaxis: 'y2',         
      },      
    ]}
    layout={{
      title: `Buyer Lead Count and RPL ${data && data?.length > 0 ? '('+ data?.[0]?.BuyerName +')' : ''}`,
      yaxis: {title: 'Sold Leads'},
      yaxis2: {
        title: 'RPL',
        overlaying: 'y',
        side: 'right',
        tickprefix: '$'
      },
      legend: {
        orientation: 'h',        
        y:10,        
      }
    }}
    style={{width: "100%", height: "480px"}}
  />);
}

export default SalesScreenLeadCountGraph;

