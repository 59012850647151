import { useLazyQuery } from "@apollo/react-hooks";
import { Box, Grid } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { GET_SALES_SCREEN } from "../../common/models/salesScreen";
import { GetSalesScreens } from "../../common/models/types/GetSalesScreens";
import { getTimezoneSetting, ISODateFormat, LDPUIDateFormat } from "../../common/utils/date";
import { RootState } from "../../state";
import {
  salesScreenSearchStateProps,
  saveSalesScreenSearch,
  setSalesScreenDataUpdate,
} from "../../state/salesScreenReducer";
import { tabsSectionStateProps } from "../../state/tabsReducer";
import SalesScreenListFilters from "./salesScreenFilters";
import SalesScreenLeadCountByDaily from "./SalesScreenLeadCountByDaily";
import SalesScreenLeadCountByHourPlot from "./SalesScreenLeadCountByHourPlot";
import SalesScreenLeadCountByHourTable from "./SalesScreenLeadCountByHourTable";
import SalesScreenLeadCountToday from "./SalesScreenLeadCountByToday";
import { eventTracker as tracker, EmailCharBlockers } from '../../components/tracker';
import { GoogleUserMeta, GoogleUserMetaProp } from "../../common/utils/googleUserMeta";

interface Props {
  dispatch?: Function;
  tabsSection?: tabsSectionStateProps;
  salesScreenSearch?: salesScreenSearchStateProps;
}

const SalesScreenList = ({ dispatch, salesScreenSearch }: Props) => {
  const timezone = getTimezoneSetting();

  const googleUserMeta = GoogleUserMeta();
  const eventTracker = ({ salesScreenSearch }: any) => {                
    tracker({
        name: "SalesScreen",
        caption: "Track Sales Screen",
        values: {          
          email: googleUserMeta?.email?.replace(/\@|\./g, it => EmailCharBlockers[it]) ?? null 
        } 
    });      
  }
  
  useEffect(() => {
    eventTracker({salesScreenSearch});
  }, [salesScreenSearch]);

  const [
    getSalesScreens,
    { data, error, loading },
  ] = useLazyQuery<GetSalesScreens>(GET_SALES_SCREEN, {
    fetchPolicy: "no-cache",
  });

  const [
    getSalesSummary,
    { data: dataSummary, error: errorSummary, loading: loadingSummary },
  ] = useLazyQuery<GetSalesScreens>(GET_SALES_SCREEN, {
    fetchPolicy: "no-cache",
  });

  const [currentDataMode, setCurrentDataMode] = useState<
    "" | "Summary" | "Daily" | "Hourly"
  >("");
  const [dataStartDate, setDataStartDate] = useState<string>("");

  const loadSummary = () => {
    const today = DateTime.now().setZone("US/Pacific").toFormat("yyyy-MM-dd");
    getSalesSummary({
      variables: {
        startDate: today,
        comparisonDayLookback: 7,
        timezone: "Pacific Standard Time",
        reportType: "Summary",
        verticalId: salesScreenSearch?.verticalId || 1,
        subVerticalId: salesScreenSearch?.subVerticalId || 1,
        fetchEDW: false, //always false since summary is always TODAY, fetch from ODS
      },
    });
  };

  const loadData = () => {
    dispatch &&
      dispatch(
        setSalesScreenDataUpdate(currentDataMode, {
          state: "loading",
        })
      );

    getSalesScreens({
      variables: {
        startDate: dataStartDate,
        comparisonDayLookback: salesScreenSearch?.comparisonDayLookback,
        timezone: "Pacific Standard Time",
        reportType: currentDataMode,
        verticalId: salesScreenSearch?.verticalId,
        subVerticalId: salesScreenSearch?.subVerticalId,
        fetchEDW: salesScreenSearch?.fetchEDW,
      },
    });
  };

  // update query contents as the search filter values are updated
  const refreshData = () => {
    if (
      currentDataMode === "" &&
      salesScreenSearch &&
      !!salesScreenSearch.startDate &&
      !!salesScreenSearch.verticalId &&
      !!salesScreenSearch.subVerticalId &&
      !!salesScreenSearch.comparisonDayLookback
    ) {
      setDataStartDate(
        DateTime.fromISO(salesScreenSearch.startDate, {
          zone: timezone,
          setZone: true,
        })
          .setZone("US/Pacific")
          .toFormat("yyyy-MM-dd")
      );

      setCurrentDataMode("Hourly");
    }
  };

  useEffect(() => {
    if (currentDataMode !== "") {
      loadData();
    }
  }, [currentDataMode]);

  useEffect(() => {
    if (currentDataMode !== "" && (data || error)) {
      dispatch &&
        dispatch(
          setSalesScreenDataUpdate(currentDataMode, {
            data,
            lastUpdated: DateTime.local().toFormat(LDPUIDateFormat),
            state: !!error ? "error" : "ready",
            errorDetails: !!error ? error.message : undefined,
          })
        );

      if (currentDataMode === "Hourly") {
        setCurrentDataMode("Daily");
      } else {
        setCurrentDataMode("");
      }
    }
  }, [data, error]);

  const [lastLoadId, setLastLoadId] = useState<string>("");
  const todayStart = () =>
    DateTime.local().setZone(timezone).set({ hour: 0, minute: 0, second: 0 });
    
  useEffect(() => {
    setLastLoadId(salesScreenSearch?.lastLoadedMark || "RELOAD_ALL#0");

    if (lastLoadId === "") {
      // initial load, check for interrupted loading previously
      // and force reload everything
      if(salesScreenSearch?.lastLoadedData?.Daily?.state === 'loading' ||
        salesScreenSearch?.lastLoadedData?.Summary?.state === 'loading' ||
        salesScreenSearch?.lastLoadedData?.Hourly?.state === 'loading'
        ){
          dispatch && dispatch(
            saveSalesScreenSearch({
              ...salesScreenSearch,
              startDate: todayStart().toFormat(ISODateFormat),
              lastLoadedMark: `RELOAD_ALL#${DateTime.now().toMillis()}`,
            })
          );
      }
      return;
    }

    if (
      salesScreenSearch?.lastLoadedMark?.startsWith("RELOAD_ALL") ||
      salesScreenSearch?.lastLoadedMark?.startsWith("RELOAD_SUMMARY")
    ) {
      loadSummary();
    } else {
      refreshData();
    }
  }, [salesScreenSearch?.lastLoadedMark]);

  useEffect(() => {
    if (dataSummary && !errorSummary) {
      dispatch &&
        dispatch(
          setSalesScreenDataUpdate("Summary", {
            data: dataSummary,
            lastUpdated: DateTime.local().toFormat(LDPUIDateFormat),
            state: !!error ? "error" : "ready",
            errorDetails: !!error ? error.message : undefined,
          })
        );

      if (!lastLoadId.startsWith("RELOAD_SUMMARY")) {
        // lastLoadId is either RELOAD_ALL or RELOAD_REST
        refreshData();
      }
    }
  }, [dataSummary, errorSummary]);

  return (
    <Fragment>
      <Box p={0}>
        <SalesScreenListFilters loading={loading || loadingSummary} dateless />

        <Box>
          <SalesScreenLeadCountToday
            loading={loadingSummary}
            data={salesScreenSearch?.lastLoadedData?.Summary?.data}
          />
        </Box>
      </Box>

      <Box p={0} mt={6} width="100%" position="relative">
        <SalesScreenListFilters loading={loading || loadingSummary} />
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <SalesScreenLeadCountByHourTable />
            </Grid>
            <Grid item xs={12}>
              <SalesScreenLeadCountByHourPlot />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box border={1} width="100%" position="relative">
        <SalesScreenLeadCountByDaily />
      </Box>

      <Grid item xs={12}>
        <br />
        <br />
      </Grid>
    </Fragment>
  );
};

export default connect(
  (state: RootState) => ({
    tabsSection: state.tabsSection,
    salesScreenSearch: state.salesScreenSearch,
  }),
  null
)(SalesScreenList);
