import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { ReactNode } from "react";

interface Props {
  rows?: number;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingGrid: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    loadingPaper: {
      height: 40,
    },
  })
);

const LoadingTableSkeleton = ({ rows, children }: Props ) => {
  const classes = useStyles();

  return <Grid container justify="center" className={classes.loadingGrid} spacing={2}>
    <Grid key='loadingTitleRow' item xs={12}>
      {children}
    </Grid>
    { [...Array(rows || 5)].map((e, i) => {
      return <Grid key={`skelrow-${i}`} item xs={12}>
        <Skeleton width="100%" className={classes.loadingPaper} />
      </Grid>
    }) }
  </Grid>;
}

export default LoadingTableSkeleton;