import gql from "graphql-tag";

export const GET_SALES_SCREEN = gql`
  query GetSalesScreens($startDate: DateTime, $comparisonDayLookback: Int, $timezone: String, $reportType: String, $verticalId: Int, $subVerticalId: Int, $fetchEDW: Boolean) 
  {
    LDPIngestQueryGroup {
      GetOpsScreenInsuranceSaleByBuyer(
        StartDateOps: $startDate, 
        ComparisonDayLookbackOps: $comparisonDayLookback, 
        TimeZoneOps: $timezone, 
        ReportTypeOps: $reportType, 
        VerticalIdOps: $verticalId, 
        SubVerticalIdOps: $subVerticalId,
        FetchEDW: $fetchEDW )    
    }
  }
`;

export const GET_BUYER_SALES_CAP_DETAILS = gql`
  query GetBuyerSalesCapDetails($startDate: DateTime, $timezone: String, $buyerName: String, $verticalId: Int, $subVerticalId: Int) 
  {
    LDPIngestQueryGroup {
      GetOpsScreenInsuranceSalesBuyerCapDetails(StartDateOps: $startDate, TimeZoneOps: $timezone, BuyerNameOps: $buyerName, VerticalIdOps: $verticalId, SubVerticalIdOps: $subVerticalId)    
    }
  }
`;