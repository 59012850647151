import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DateTime } from "luxon";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import "regenerator-runtime/runtime";
import { GET_BUYER_SALES_CAP_DETAILS } from "../../common/models/salesScreen";
import {
  GetBuyerSalesCapDetails,
  GetBuyerSalesCapDetailsVariables,
} from "../../common/models/types/GetBuyerSalesCapDetails";
import {
  dateToMomentTz,
  getTimezoneSetting,
  ISODateFormat,
  withinPast,
} from "../../common/utils/date";
import { LDPUIDataTable, queryLoad } from "../../components";
import { RootState } from "../../state";
import { DataCacheState } from "../../state/dataCacheReducer";
import {
  salesScreenSearchStateProps,
  triggerSalesBuyerCapDetailTrigger,
  triggerSalesBuyerCapDetailUpdate,
} from "../../state/salesScreenReducer";

interface Props {
  salesScreenSearch?: salesScreenSearchStateProps;
  entityDataCache?: DataCacheState;
  dispatch?: Function;
  buyerName: string;
  /* startDate: string;
  vertical: number;
  subVertical: number; */
}

interface OptionProps {
  label: string;
  value: any;
  parent?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    input: {
      backgroundColor: "#FFFFFF",
    },
  })
);

const SalesBuyerCapacityDetails = ({
  dispatch,
  entityDataCache,
  salesScreenSearch,
  buyerName,
}: Props) => {
  const classes = useStyles();
  const timezone = getTimezoneSetting();

  const [
    getBuyerSalesCapDetails,
    { data: capData, error: capError, loading: capLoading },
  ] = useLazyQuery<GetBuyerSalesCapDetails, GetBuyerSalesCapDetailsVariables>(
    GET_BUYER_SALES_CAP_DETAILS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [buyerSalesCapacityData, setBuyerSalesCapacityData] = useState<any[]>(
    []
  );
  const [lastSearchMark, setLastSearchMark] = useState<string | undefined>(
    salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.mark
  );

  const [VerticalList, setVerticalList] = useState<OptionProps[]>([]);
  const [selectedVertical, setSelectedVertical] = useState<OptionProps | null>(
    null
  );

  const [SubVerticalList, setSubVerticalList] = useState<OptionProps[]>([]);
  const [
    selectedSubVertical,
    setSelectedSubVertical,
  ] = useState<OptionProps | null>(null);

  const todayStart = () =>
    DateTime.local().setZone(timezone).set({ hour: 0, minute: 0, second: 0 });

  /* these are the actual dates that we use **/
  const [startDate, setStartDate] = useState(todayStart());

  /** use for displaying dates on components that doesnt support moment objects */
  const [startDateNative, setStartDateNative] = useState<Date>(
    todayStart().toJSDate()
  );

  /** update native JS date object when chosen date is updated */
  useEffect(() => {
    setStartDateNative(new Date(startDate.toISO({ includeOffset: false })));
  }, [startDate]);

  useEffect(() => {
    // console.log("start date", salesScreenSearch?.startDate);
    const val = DateTime.fromFormat(
      salesScreenSearch?.startDate,
      ISODateFormat,
      { zone: "US/Pacific" }
    );

    setStartDate(val);
  }, [salesScreenSearch?.startDate]);

  // component boot
  useEffect(() => {
    const dateToday = DateTime.now()
      .setZone("US/Pacific")
      .toFormat("yyyy-MM-dd");
    if (
      !salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName] ||
      // negate result by any false condition below
      !(
        salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName] && // old data exists
        // data cache exists
        !!salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.data &&
        //same start date
        salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.date ===
          salesScreenSearch?.startDate &&
        // date cached is not today
        salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.date !==
          dateToday &&
        // save vertical
        salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.vertical ===
          salesScreenSearch?.verticalId &&
        // same sub vertical
        salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]
          ?.subVertical === salesScreenSearch?.subVerticalId &&
        // data cache is not empty
        salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.data
          ?.LDPIngestQueryGroup?.GetOpsScreenInsuranceSalesBuyerCapDetails !==
          "[]" &&
        // data has been loaded from the past 5 minutes
        withinPast(
          salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]
            ?.updatedAt || 0,
          { minutes: 5 }
        )
      )
    ) {
      // data is not present or something's not match, refresh data
      updateTableData();
    }
  }, []);

  // update vertical options list when data cache is loaded
  useEffect(() => {
    if (!entityDataCache?.ActiveVerticals) return;

    setVerticalList(
      entityDataCache?.ActiveVerticals?.data?.map((it) => ({
        label: `${it?.VerticalName}`,
        value: it?.VerticalId,
      })) ?? []
    );
  }, [entityDataCache?.ActiveVerticals]);

  // set default values when vertical options are loaded
  useEffect(() => {
    const selected = VerticalList.find(
      (option) => Number(option.value) === salesScreenSearch?.verticalId
    );
    if (selected) {
      setSelectedVertical(selected);
    } else {
      VerticalList.length > 0 && setSelectedVertical(VerticalList[0]);
    }
  }, [VerticalList]);

  /** sub-vertical option list */
  useEffect(() => {
    if (selectedVertical && entityDataCache?.ActiveSubVerticals) {
      const filteredSubVerticals =
        entityDataCache?.ActiveSubVerticals?.data?.filter(
          (it) => it?.VerticalId === selectedVertical?.value
        ) ?? [];

      if (filteredSubVerticals?.length > 0) {
        setSubVerticalList(
          filteredSubVerticals?.map((it) => ({
            label: `${it?.SubVerticalName}`,
            value: it?.SubVerticalId,
          }))
        );
      }
    }
  }, [selectedVertical, entityDataCache?.ActiveSubVerticals]);

  // set default values when sub-vertical options are loaded
  useEffect(() => {
    const selected = SubVerticalList.find(
      (option) => Number(option.value) === salesScreenSearch?.subVerticalId
    );
    if (selected) {
      setSelectedSubVertical(selected);
    } else {
      SubVerticalList.length > 0 && setSelectedSubVertical(SubVerticalList[0]);
    }
  }, [SubVerticalList]);

  // perform data load when search mark changes
  useEffect(() => {
    const mark =
      salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.mark;

    if (!!mark && mark !== lastSearchMark) {
      setLastSearchMark(mark);
      getBuyerSalesCapDetails({
        variables: {
          startDate:
            salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.date,
          timezone: "Pacific Standard Time",
          buyerName,
          verticalId:
            salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]
              ?.vertical,
          subVerticalId:
            salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]
              ?.subVertical,
        },
      });
    }
  }, [salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.mark]);

  // saved loaded buyer sales capacity details data to redux
  useEffect(() => {
    if (capData && !capError) {
      dispatch &&
        dispatch(triggerSalesBuyerCapDetailUpdate(buyerName, capData));
    }
  }, [capData, capError]);

  // process table data when loaded and saved on redux
  useEffect(() => {
    setBuyerSalesCapacityData(
      JSON.parse(
        salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.data
          ?.LDPIngestQueryGroup?.GetOpsScreenInsuranceSalesBuyerCapDetails ||
          "[]"
      )
    );
  }, [salesScreenSearch?.loadedBuyerSalesCapacities?.[buyerName]?.data]);

  useEffect(() => {
    updateTableData();
  }, [
    salesScreenSearch?.verticalId,
    salesScreenSearch?.subVerticalId,
    salesScreenSearch?.startDate,
  ]);

  const updateTableData = () => {
    dispatch &&
      dispatch(
        triggerSalesBuyerCapDetailTrigger(
          buyerName,
          `${DateTime.now().toMillis()}`,
          salesScreenSearch?.startDate,
          salesScreenSearch?.verticalId,
          salesScreenSearch?.subVerticalId
        )
      );
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "ContractName",
      label: "Contract Name",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: (value) => ({
          style: {
            fontWeight: "bold",
          },
        }),
      },
    },
    {
      name: "LeadCount",
      label: "Lead Count",
      options: {
        viewColumns: true,
        filter: true,
        setCellHeaderProps: (value) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        setCellProps: (value) => ({
          style: {
            /* textAlign: "right", */
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
          },
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },
    },
    {
      name: "DailyContractCapacity",
      label: "Daily Capacity",
      options: {
        viewColumns: true,
        filter: true,
        setCellHeaderProps: (value) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        setCellProps: (value) => ({
          style: {
            /* textAlign: "right", */
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
          },
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },
    },
    {
      name: "PercentToCapacity",
      label: "Percent Capacity",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: (value) => ({
          style: {
            /* textAlign: "right", */
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
          },
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <>
            <NumberFormat
              value={(value * 100).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
            %
          </>
        ),
      },
    },
    {
      name: "IsAtDailyCap",
      label: "Daily Cap Reached",
      options: {
        viewColumns: true,
        filter: true,
        setCellProps: (value) => ({
          style: {
            /* textAlign: "right", */
            fontWeight: "bold",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
          },
        }),
        customBodyRender: (value: any, tableMeta: any) => (
          <>{value === "N" ? "No" : "Yes"}</>
        ),
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "BuyerName",
      direction: "asc",
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [25, 50, 100],
    fixedHeader: true,
    fixedSelectColumn: true,
    setRowProps: (row, rowIndex) => {
      if (row[4] !== "N") {
        return {
          style: {
            backgroundColor: "#abff85",
          },
        };
      }

      return {
        style: {
          backgroundColor: "#ff858d",
        },
      };
    },
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={2}>
              <Autocomplete
                id="vertical-input"
                disabled
                options={VerticalList || []}
                getOptionLabel={(option) => option.label}
                value={selectedVertical}
                onChange={(e: any, value: OptionProps | null) => {
                  setSelectedVertical(value);
                }}
                className={classes.input}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vertical"
                    variant="outlined"
                    name="VerticalId"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Autocomplete
                id="subvertical-input"
                options={SubVerticalList || []}
                getOptionLabel={(option) => option.label}
                value={selectedSubVertical}
                onChange={(e: any, value: OptionProps | null) => {
                  setSelectedSubVertical(value);
                }}
                disabled
                className={classes.input}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="SubVertical"
                    variant="outlined"
                    name="SubVerticalId"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
              <DatePicker
                autoComplete="off"
                id="start-date-input"
                disabled
                name="StartDate"
                className={classes.input}
                dateFormat={"yyyy-MM-dd"}
                selected={startDateNative}
                onChange={(date) => {
                  if (!date) return;

                  let theDate = null;
                  if (date instanceof Date) {
                    // interpret this local date selection as time within `timezone`
                    // cause that is what the user thinks is doing
                    // Refer to preferredTimezone user preference
                    theDate = dateToMomentTz(date, timezone);
                  } else {
                    return;
                  }

                  setStartDate(theDate);
                }}
                customInput={
                  <TextField
                    label={`Start Date (US/Pacific)`}
                    variant="outlined"
                    name="StartDate"
                    autoComplete="off"
                    fullWidth
                  />
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
              <Button
                variant="contained"
                disabled={loading}
                size="large"
                color="primary"
                fullWidth
                style={{ height: "100%" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  updateTableData();
                }}
              >
                Update Data
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {queryLoad([!!capLoading, !!loading], [capError]) || (
          <LDPUIDataTable
            ldpTableId="sales-screen-lead-count-hourly"
            restoreFilters={true}
            title={
              <Typography variant="h6">
                {buyerName} Day Capacity Details
              </Typography>
            }
            data={buyerSalesCapacityData}
            columns={columns}
            options={options}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    salesScreenSearch: state.salesScreenSearch,
    entityDataCache: state.entityDataCache,
  }),
  null
)(SalesBuyerCapacityDetails);
