import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  height?:number;
  barWidth?:number;
  bars?: 6|12;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingGrid: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    loadingPaper: {
      maxWidth: 50,
      minWidth: 25,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);

const LoadingGraphSkeleton = ({ height, barWidth, bars }: Props ) => {
  const classes = useStyles();

  return <Grid container justify="center" className={classes.loadingGrid} spacing={2}>
    { [...Array(bars || 6)].map((e, i) => {
      return <Grid key={`col-${i}`} item xs={(12 / (bars || 6))}>
        <Skeleton className={classes.loadingPaper} style={{
          height: height || 250,
          width: barWidth || 50,
        }} />
      </Grid>
    }) }
  </Grid>;
}

export default LoadingGraphSkeleton;