import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TableCell,
  TableFooter,
  TableRow,
  Theme,
  Typography,
  Zoom,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  MUIDataTableColumnDef,
  MUIDataTableColumnState,
  MUIDataTableOptions,
} from "mui-datatables";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import "regenerator-runtime/runtime";
import { LDPUIDataTable } from "../../components";
import { buyerFilter, leadsSoldFilter } from "../../components/customMuiDatatableFilter";
import LoadingTableSkeleton from "../../components/loadingTableSkeleton";
import { RootState } from "../../state";
import { salesScreenSearchStateProps } from "../../state/salesScreenReducer";

interface Props {
  salesScreenSearch?: salesScreenSearchStateProps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    loadingGrid: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    loadingPaper: {
      height: 30,
    },
    salesScreenFooterCell: {
      padding: "0 10px",
      fontWeight: "bold",
      color: "#000",
      fontSize: "1em",
      "& .preLabel": {
        color: "#787878",
      },
    },
  })
);

const SalesScreenLeadCountByHourTable = ({ salesScreenSearch }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [salesScreenData, setSalesScreenData] = useState<any[]>([]);

  useEffect(() => {
    const dataState = salesScreenSearch?.lastLoadedData?.Hourly?.state || "";

    setLoading(dataState === "loading");

    if (dataState === "ready") {
      const screenData: any[] = JSON.parse(
        salesScreenSearch?.lastLoadedData?.Hourly?.data?.LDPIngestQueryGroup
          ?.GetOpsScreenInsuranceSaleByBuyer ?? "[]"
      );

      // add total column per row
      screenData.forEach((row) => {
        let total = 0;
        for(let col in row){
          if(col !== 'BuyerName' && col !== 'BuyerId'){
            total += parseInt(row[col]);
          }
        }

        row['Total'] = total;
      });

      setSalesScreenData(screenData);
    }
  }, [salesScreenSearch]);

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "BuyerName",
      label: "Buyer Name",
      options: {
        viewColumns: true,
        filter: true,
        ...buyerFilter
      },
    },
    {
      name: "Total",
      label: "Total",
      options: {
        viewColumns: true,
        filter: true,
        ...leadsSoldFilter
      },
    },
  ];

  // add columns from hour 0 to hour 23
  for (let i = 0; i < 24; i++) {
    columns.splice(columns.length - 1, 0, {
      name: `HR${i < 10 ? `0${i}` : i}`,
      label: `${i < 10 ? `0${i}` : i}`,
      options: {
        viewColumns: true,
        filter: true,
        customBodyRender: (value: string) => {
          const count = parseInt(value);

          return count > 0 ? (
            <NumberFormat
              value={count}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : null;
        },
        ...leadsSoldFilter
      },
    });
  }

  const getFooterCell = (showEffects:boolean, cols: any[], columnName: string, index: number) => {
    if (columnName === "BuyerName")
      return (
        <TableCell key={index}>
          <Typography component="h4" color="textPrimary">
            Total
          </Typography>
        </TableCell>
      );
    else {
      let total = salesScreenData.reduce(
        (total, it, i) => total + (isRowFiltered(cols, i) ? 0 : it[columnName]),
        0
      );
      total = parseInt(total.toFixed(0));

      return (
        <TableCell key={index} className={classes.salesScreenFooterCell} style={showEffects ? { backgroundColor: "#ede4e4" } : {}}>
          <NumberFormat
            value={total}
            displayType={"text"}
            thousandSeparator={true}
          />
        </TableCell>
      );
    }
  };

  const isRowFiltered = (colums: any[], index:number) => {
    let filtered = false;
    colums.forEach(column => {
      if(Boolean(column.filter || true)){
        const columnValue = salesScreenData[index][column.name];

        if(column.filterOptions?.logic){
          filtered ||= column.filterOptions.logic(columnValue, column.filterList || []);
        } else {
          const filterList = column.filterList || [];
          filtered ||= !!filterList.length ? !filterList.map((f) => `${f}`.toLowerCase()).includes(columnValue.toLowerCase()) : false;
          
        }
      }
    });

    return filtered;
  }

  const options: MUIDataTableOptions = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "BuyerName",
      direction: "asc",
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [25, 50, 100],
    fixedHeader: true,
    fixedSelectColumn: true,
    customTableBodyFooterRender: (opts) => {
      let filtersInEffect = false;
      opts.columns.forEach(col => {
        filtersInEffect ||= (col.filterList || []).length > 0;
      });

      return (
      <TableFooter>
        <TableRow>
          {opts.selectableRows !== "none" ? <TableCell /> : null}
          {opts.columns.map((column: MUIDataTableColumnState, index: number) => {
            const cell = column.display === "true" ? getFooterCell(filtersInEffect, opts.columns, column.name, index) : null;
            return filtersInEffect && !!cell ? (
              <Zoom in>
                {cell}
              </Zoom>
            ) : cell;
          })}
        </TableRow>
      </TableFooter>
    )},
  };

  if (loading) {
    return <LoadingTableSkeleton rows={4}>
      <Typography variant="h6">Lead Count By Buyer Hourly</Typography>
    </LoadingTableSkeleton>;
  }

  return (
    <LDPUIDataTable
      ldpTableId="sales-screen-lead-count-hourly"
      restoreFilters={true}
      title={<Typography variant="h6">Lead Count By Buyer Hourly</Typography>}
      data={salesScreenData}
      columns={columns}
      options={options}
    />
  );
};

export default connect(
  (state: RootState) => ({
    salesScreenSearch: state.salesScreenSearch,
  }),
  null
)(SalesScreenLeadCountByHourTable);
