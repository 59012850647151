import "regenerator-runtime/runtime";

import {
  CircularProgress,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import loadable from '@loadable/component';

import { createPallete } from "../../common/utils/color";
import { RootState } from "../../state";
import { salesScreenSearchStateProps } from "../../state/salesScreenReducer";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import LoadingGraphSkeleton from "../../components/loadingGraphSkeleton";

const PlotComponent = loadable(() => import('react-plotly.js'));

interface Props {
  salesScreenSearch?: salesScreenSearchStateProps;
}

interface BuyerData {
  BuyerId: number;
  BuyerName: string;
  HR00: number;
  HR01: number;
  HR02: number;
  HR03: number;
  HR04: number;
  HR05: number;
  HR06: number;
  HR07: number;
  HR08: number;
  HR09: number;
  HR10: number;
  HR11: number;
  HR12: number;
  HR13: number;
  HR14: number;
  HR15: number;
  HR16: number;
  HR17: number;
  HR18: number;
  HR19: number;
  HR20: number;
  HR21: number;
  HR22: number;
  HR23: number;
  Total?:number;
  Trace?: {
    x: number[],
    y: number[];
  }
}

interface BuyerOption {
  id: number;
  label: string;
  value: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    input: {
      backgroundColor: "#FFFFFF",
    },
    loadingGrid: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    loadingPaper: {
      height: 250,
      width: 50,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);

const SalesScreenLeadCountByHourPlot = ({ salesScreenSearch }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBuyer, setSelectedBuyer] = useState<BuyerOption[] | undefined>(undefined);
  const [salesScreenData, setSalesScreenData] = useState<BuyerData[]>([]);

  useEffect(() => {
    const dataState = salesScreenSearch?.lastLoadedData?.Hourly?.state || "";

    setLoading(dataState === "loading");

    if (dataState === "ready") {
      const screenData: any[] = JSON.parse(
        salesScreenSearch?.lastLoadedData?.Hourly?.data?.LDPIngestQueryGroup
          ?.GetOpsScreenInsuranceSaleByBuyer ?? "[]"
      );

      // add total column per row
      screenData.forEach((row) => {
        let total = 0;
        const trace:{ [hour:string]: number; } = {};
        for(let col in row){
          if(col !== 'BuyerName' && col !== 'BuyerId' && col !== 'Total' && col.startsWith('HR')){
            const hour = Number(col.replace('HR', ''));
            const leads = parseInt(row[col]);

            total += leads;
            trace[hour] = leads;
          }
        }

        row.Total = total;

        const nonZeroTraces = Object.keys(trace).map((hr, idx) => idx).map((hr) => ({ hr, leads: trace[hr] })); //.filter(data => data.leads > 0);
        row.Trace = {
          x: nonZeroTraces.map(data => data.hr),
          y: nonZeroTraces.map(data => data.leads),
        }
        
      });

      setSalesScreenData(screenData);
    }
  }, [salesScreenSearch]);

  const [traces, setTraces] = useState<any[]>([]);

  useEffect(() => {
    const newTraces: any[] = [];
    const pallete = createPallete(salesScreenData.length < 2 ? 5 : salesScreenData.length);

    const selectedBuyerIds = (selectedBuyer || []).map(b => b.id);

    salesScreenData
      .sort((b1, b2) => ((b2.Total || 0) - (b1.Total || 0)))
      .filter((b) => (!selectedBuyer || (selectedBuyer && selectedBuyer.length === 0) || selectedBuyerIds.includes(b.BuyerId) ))
      .slice(0, 10)
      .forEach((buyerData, buyerIndex) => {

      const name = buyerData.BuyerName;

      newTraces.push({
        x: buyerData.Trace?.x || [],
        y: buyerData.Trace?.y || [],
        mode: 'lines+markers',
        name: name.length > 15 ? `${name.substring(0, 12)}...` : name,
        text: name,
        line: {
          color: pallete[buyerIndex],
          width: 3
        },
        marker: {
          color: pallete[buyerIndex],
          size: 6
        },
        label: {
          text: buyerData.BuyerName
        },
        
        hoverinfo: 'x+y+text',
        textinfo: 'label+value'
      });
    });

    setTraces(newTraces);

  }, [salesScreenData, selectedBuyer]);

  if (loading) {
    return <LoadingGraphSkeleton />;
  }

  return (
    <Fragment>
      
        <Grid container spacing={5}>
          <Hidden xsDown>
            <Grid item sm={2}></Grid>
          </Hidden>
          <Grid item sm={8} xs={12}>
            <Autocomplete
              id="buyer-filter-input"
              fullWidth
              multiple
              options={salesScreenData.map((buyer) => (
                {
                  id: buyer.BuyerId,
                  label: buyer.BuyerName,
                  value: buyer.BuyerId
                }
              ))}
              getOptionLabel={(option) => option.label}
              value={selectedBuyer}
              onChange={(event: any, newValue) => {
                setSelectedBuyer(newValue);
              }}
              className={classes.input}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter Buyer"
                  variant="outlined"
                  name="BuyerId"
                />
              )}
            />
          </Grid>
          <Hidden xsDown>
            <Grid item sm={2}></Grid>
          </Hidden>

          <Grid item xs={12}>
            <PlotComponent 
              style={{
                width: "100%",
                minHeight: "450px"
              }}
              data={traces}
              layout={{
                xaxis: {
                  title: 'Hour',
                  showgrid: true,
                  zeroline: false
                },
                yaxis: {
                  title: 'Leads',
                  showline: true
                },
                legend: {
                  traceorder: 'reversed',
                }
              }}
              config={{
                responsive: true
              }} />
          </Grid>
        </Grid>
    </Fragment>
    
  );
};

export default connect(
  (state: RootState) => ({
    salesScreenSearch: state.salesScreenSearch,
  }),
  null
)(SalesScreenLeadCountByHourPlot);
