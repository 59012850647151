import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DateTime } from "luxon";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import "regenerator-runtime/runtime";
import { GetSubVertical } from "../../common/models/types/GetSubVertical";
import { GetVertical } from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import {
  dateToMomentTz,
  getTimezoneSetting,
  ISODateFormat,
  withinPast,
} from "../../common/utils/date";
import { RootState } from "../../state";
import {
  DataCacheState,
  updateSubVerticals,
  updateVerticals,
} from "../../state/dataCacheReducer";
import {
  salesScreenSearchStateProps,
  saveSalesScreenSearch,
} from "../../state/salesScreenReducer";

interface Props {
  dispatch?: Function;
  salesScreenSearch?: salesScreenSearchStateProps;
  entityDataCache?: DataCacheState;
  loading: boolean;
  dateless?: boolean;
}

interface OptionProps {
  label: string;
  value: any;
  parent?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    input: {
      backgroundColor: "#FFFFFF",
    },
  })
);

const timezone = getTimezoneSetting();

const SalesScreenListFilters = ({
  dispatch,
  entityDataCache,
  salesScreenSearch,
  loading: parentLoading,
  dateless,
}: Props) => {
  const [
    getVerticals,
    { data: verticalData, error: verticalDataError },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);
  const [
    getSubVerticals,
    { data: subVerticalData, error: subVerticalDataError },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const classes = useStyles();

  const [VerticalList, setVerticalList] = useState<OptionProps[]>([]);
  const [selectedVertical, setSelectedVertical] = useState<OptionProps | null>(
    null
  );

  const [SubVerticalList, setSubVerticalList] = useState<OptionProps[]>([]);
  const [
    selectedSubVertical,
    setSelectedSubVertical,
  ] = useState<OptionProps | null>(null);

  const [accountManager, setAccountManagerFilter] = useState<string | null>(
    null
  );

  const [loading, setLoading] = useState<boolean>(true);

  /** begin loading selection values */
  useEffect(() => {
    if (
      !entityDataCache?.ActiveVerticals ||
      (entityDataCache?.ActiveVerticals &&
        !withinPast(entityDataCache?.ActiveVerticals.time, { days: 1 }))
    ) {
      getVerticals({ variables: { where: "IsActive = true" } });
    }
  }, []);

  /** vertical option list */
  useEffect(() => {
    if (verticalData && !verticalDataError) {
      dispatch &&
        dispatch(
          updateVerticals(verticalData?.LDPConfigQueryGroup?.Vertical || [])
        );
    }
  }, [verticalData, verticalDataError]);

  // update vertical options list when data cache is loaded
  useEffect(() => {
    if (!entityDataCache?.ActiveVerticals) return;

    setVerticalList(
      entityDataCache?.ActiveVerticals?.data?.map((it) => ({
        label: `${it?.VerticalName}`,
        value: it?.VerticalId,
      })) ?? []
    );

    if (
      !entityDataCache?.ActiveSubVerticals ||
      (entityDataCache?.ActiveSubVerticals &&
        !withinPast(entityDataCache?.ActiveSubVerticals.time, { days: 1 }))
    ) {
      getSubVerticals({ variables: { where: "IsActive = true" } });
    }
  }, [entityDataCache?.ActiveVerticals]);

  useEffect(() => {
    if (subVerticalData && !subVerticalDataError) {
      // setLoading(false || parentLoading);
      dispatch &&
        dispatch(
          updateSubVerticals(
            subVerticalData?.LDPConfigQueryGroup?.SubVertical || []
          )
        );
    }
  }, [subVerticalData, subVerticalDataError]);

  // set default values when vertical options are loaded
  useEffect(() => {
    const selected = VerticalList.find(
      (option) => Number(option.value) === salesScreenSearch?.verticalId
    );
    if (selected) {
      setSelectedVertical(selected);
    } else {
      VerticalList.length > 0 && setSelectedVertical(VerticalList[0]);
    }
  }, [VerticalList, salesScreenSearch?.verticalId]);

  /** sub-vertical option list */
  useEffect(() => {
    if (selectedVertical && entityDataCache?.ActiveSubVerticals) {
      const filteredSubVerticals =
        entityDataCache?.ActiveSubVerticals?.data?.filter(
          (it) => it?.VerticalId === selectedVertical?.value
        ) ?? [];

      if (filteredSubVerticals?.length > 0) {
        setSubVerticalList(
          filteredSubVerticals?.map((it) => ({
            label: `${it?.SubVerticalName}`,
            value: it?.SubVerticalId,
          }))
        );
      }
    }
  }, [selectedVertical, entityDataCache?.ActiveSubVerticals]);

  // set default values when sub-vertical options are loaded
  useEffect(() => {
    const selected = SubVerticalList.find(
      (option) => Number(option.value) === salesScreenSearch?.subVerticalId
    );
    if (selected) {
      setSelectedSubVertical(selected);
    } else {
      SubVerticalList.length > 0 && setSelectedSubVertical(SubVerticalList[0]);
    }
  }, [SubVerticalList, salesScreenSearch?.subVerticalId]);

  const todayStart = () =>
    DateTime.local().setZone(timezone).set({ hour: 0, minute: 0, second: 0 });

  /** use for displaying dates on components that doesnt support moment objects */
  const [startDateNative, setStartDateNative] = useState<Date>(
    todayStart().toJSDate()
  );

  /* these are the actual dates that we use **/
  const [startDate, setStartDate] = useState<DateTime | null>(null);

  // on component load and redux state has been retrieved
  useEffect(() => {
    if (!salesScreenSearch) return;

    if (!dateless) {
      // do this on full versioned form
      const today = todayStart();
      const todayFormatted = today.toFormat(ISODateFormat);

      /* if (!startDate && salesScreenSearch?.startDate !== todayFormatted) { */
      if (!salesScreenSearch?.startDate) {
        setStartDate(today);

        // signal to reload data
        dispatch &&
          dispatch(
            saveSalesScreenSearch({
              startDate: todayFormatted,
              verticalId: salesScreenSearch.verticalId,
              subVerticalId: salesScreenSearch.subVerticalId,
              accountManager,
              comparisonDayLookback: salesScreenSearch?.comparisonDayLookback,
              lastLoadedMark: `RELOAD_ALL#${DateTime.now().toMillis()}`,
              loadedBuyerSalesCapacities:
                salesScreenSearch?.loadedBuyerSalesCapacities || {},
            })
          );
      } else {
        setStartDate(
          salesScreenSearch?.startDate
            ? DateTime.fromFormat(salesScreenSearch?.startDate, ISODateFormat, {
                zone: timezone,
                setZone: true,
              })
            : todayStart());
      }
    } else {
      // standard load from cache
      try {
        setStartDate(
          salesScreenSearch?.startDate
            ? DateTime.fromFormat(salesScreenSearch?.startDate, ISODateFormat, {
                zone: timezone,
                setZone: true,
              })
            : todayStart()
        );
      } catch (error) {}
    }
  }, [salesScreenSearch]);

  /**
   *
   * @param date Date object in users system time.
   * @returns
   */
  const handleSetStartDate = (date: Date | null) => {
    if (!date) return;

    let theDate = null;
    if (date instanceof Date) {
      // interpret this local date selection as time within `timezone`
      // cause that is what the user thinks is doing
      // Refer to preferredTimezone user preference
      theDate = dateToMomentTz(date, timezone);
    } else {
      return;
    }

    setStartDate(theDate);
  };

  const [comparisonDayLookback, setComparisonDayLookback] = useState<number>(
    salesScreenSearch?.comparisonDayLookback || 7
  );
  const handleChangeLookback = (e: BaseSyntheticEvent) => {
    try {
      const intValue = parseInt(e.target.value);

      setComparisonDayLookback(intValue < 1 ? 1 : intValue);
    } catch (error) {
      setComparisonDayLookback(7);
    }
  };

  /** Update Filter values in Redux salesScreenList */
  const handleUpdateSalesScreenFilters = () => {
    // console.log('update filters');

    const loadMark = dateless
      ? Number(selectedVertical?.value) !== salesScreenSearch?.verticalId ||
        Number(selectedSubVertical?.value) !== salesScreenSearch?.subVerticalId
        ? "RELOAD_ALL"
        : "RELOAD_SUMMARY"
      : Number(selectedVertical?.value) !== salesScreenSearch?.verticalId ||
        Number(selectedSubVertical?.value) !== salesScreenSearch?.subVerticalId
      ? "RELOAD_ALL"
      : "RELOAD_REST";

      
      const _startDate = dateless ? DateTime.fromISO(`${salesScreenSearch?.startDate}`) : startDate;
      const edwDaysMargin = Number(process.env.FETCH_EDW_DAYS_CONDITION) || 5;
      const odsStartDateTzFmt = DateTime.now().setZone(timezone).minus({days: edwDaysMargin});
      const fetchEDW = (_startDate && (_startDate < odsStartDateTzFmt)) ? true : false;

      console.log("Fetching sales screen", _startDate, edwDaysMargin, odsStartDateTzFmt, fetchEDW, salesScreenSearch);

    dispatch &&
      dispatch(
        saveSalesScreenSearch({
          startDate: dateless
            ? salesScreenSearch?.startDate
            : startDate?.toFormat(ISODateFormat),
          verticalId: selectedVertical?.value,
          subVerticalId: selectedSubVertical?.value,
          accountManager,
          comparisonDayLookback: dateless
            ? salesScreenSearch?.comparisonDayLookback
            : comparisonDayLookback,
          lastLoadedMark: `${loadMark}#${DateTime.now().toMillis()}`,
          loadedBuyerSalesCapacities:
            salesScreenSearch?.loadedBuyerSalesCapacities || {},
          fetchEDW: fetchEDW,
        })
      );
  };

  /** update native JS date object when chosen date is updated */
  useEffect(() => {
    if (startDate) {
      setStartDateNative(new Date(startDate.toISO({ includeOffset: false })));
    }
  }, [startDate]);

  /* useEffect(() => {
    console.log("StartDateNative Set", dateless, startDateNative);
  }, [startDateNative]); */

  /** set loading status when parent component is loading and or data sources are loading */
  useEffect(() => {
    setLoading(
      salesScreenSearch?.lastLoadedData?.Daily?.state === "loading" ||
        salesScreenSearch?.lastLoadedData?.Hourly?.state === "loading" ||
        parentLoading
    );
  }, [salesScreenSearch, parentLoading]);

  return (
    <Grid item xs={12}>
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={2}>
            <Autocomplete
              id="vertical-input"
              disabled={loading}
              options={VerticalList || []}
              getOptionLabel={(option) => option.label}
              value={selectedVertical}
              onChange={(e: any, value: OptionProps | null) => {
                setSelectedVertical(value);
              }}
              className={classes.input}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vertical"
                  variant="outlined"
                  name="VerticalId"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Autocomplete
              id="subvertical-input"
              options={SubVerticalList || []}
              getOptionLabel={(option) => option.label}
              value={selectedSubVertical}
              onChange={(e: any, value: OptionProps | null) => {
                setSelectedSubVertical(value);
              }}
              disabled={!selectedVertical || loading}
              className={classes.input}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="SubVertical"
                  variant="outlined"
                  name="SubVerticalId"
                />
              )}
            />
          </Grid>

          {dateless ? (
            <>
              <Hidden smDown>
                <Grid item md={2} lg={2}>
                  {" "}
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item md={2} lg={2}>
                  {" "}
                </Grid>
              </Hidden>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6} lg={2}>
                <TextField
                  disabled={loading}
                  type="number"
                  name="comparisonDayLookback"
                  label="Comparison Day Lookback"
                  variant="outlined"
                  value={comparisonDayLookback}
                  onChange={handleChangeLookback}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <DatePicker
                  autoComplete="off"
                  id="start-date-input"
                  disabled={loading}
                  name="StartDate"
                  className={classes.input}
                  dateFormat={"yyyy-MM-dd"}
                  onChange={(date) => {
                    handleSetStartDate(date);
                  }}
                  selected={startDateNative}
                  customInput={
                    <TextField
                      label={`Start Date (${timezone})`}
                      variant="outlined"
                      name="StartDate"
                      autoComplete="off"
                      fullWidth
                    />
                  }
                />
              </Grid>
            </>
          )}

          <Hidden smDown>
            <Grid item md={2} lg={2}>
              {" "}
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={6} md={12} lg={2} >
            <Button
              variant="contained"
              disabled={loading}
              size="large"
              color="primary"
              fullWidth
              style={{ height: "100%" }}
              onClick={() => {
                handleUpdateSalesScreenFilters();
              }}
            >
              Update List
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    salesScreenSearch: state.salesScreenSearch,
    entityDataCache: state.entityDataCache,
  }),
  null
)(SalesScreenListFilters);
