import React, { useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { ContentContainer, Layout, PrivateRoute } from "../components";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { RootState } from "../state";
import { TabProps, TabsContainer } from "../components/tabs";
import { updateTabContainers } from "../state/tabsReducer";

import SalesScreenList from "../views/salesScreen/salesScreenList";

interface Props {
  dispatch: Function;
  tabGroups: any;
}

export const TAB_CONTAINER = "sales-screen-tabs";

const SalesScreen = ({ tabGroups, dispatch }: Props) => {
  
  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabSalesScreens, setTabSalesScreens] = useState<TabProps[]>([]);

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    if (props.permalink) navigate(props.permalink);
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  useEffect(() => {
    if (! tabGroups.tabContainers[TAB_CONTAINER]) {
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "SalesScreen",
          tabId: "sales-screen-list",
          content: <SalesScreenList />,
        },
      ];

      const initialLeadsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch(updateTabContainers(initialLeadsTabContainer));
    } else {
      if (tabGroups.tabContainers[TAB_CONTAINER]) {
        setTabSalesScreens(tabGroups.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(tabGroups.tabContainers[TAB_CONTAINER].tabFocus);
      }
    }
  }, [tabGroups]);

  return (
    <ContentContainer>
      <TabsContainer
        tabs={tabSalesScreens}
        onCloseTab={setTabSalesScreens}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
    </ContentContainer>
  );
};

const SalesScreenWrapper = connect((state: RootState) => ({
  tabGroups: state.tabsSection,
}), null)(SalesScreen);

export default (a: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute default component={SalesScreenWrapper} path="/salesscreen/" pagetitle="LDP Sales Screen"/>
      </Router>
    </Layout>
  );
};